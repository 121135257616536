import React from 'react';
import { Grommet } from 'grommet';
import theme from './theme';
import Router from './Router';

function App() {
  return (
    <Grommet theme={theme}>
      <Router />
    </Grommet>
  );
}

export default App;
