import capitalize from 'lodash.capitalize';
import debounce from 'lodash.debounce';

const debounceUpdate = debounce((updateFn, update) => {
  updateFn(update);
}, 400);

const normalizeCapitalization = str => {
  return str.length >= 4 ? capitalize(str) : str.toUpperCase();
};

export default {
  debounceUpdate,
  normalizeCapitalization,
};
