import React, { useContext, useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import * as R from 'ramda';
import copy from 'copy-to-clipboard';
import download from 'downloadjs';
import { Box, Button, CheckBox, DataTable } from 'grommet';
import { Copy, Download } from 'grommet-icons';
import { menuPad } from '../../../theme/constants';
import PanelHeader from '../../shared/PanelHeader';
import PanelTitle from '../../shared/PanelTitle';
import { MODES, TEMPLATE_OPTIONS } from '../Editor/modes';
import ITEContext from '../ITEContext';
import { colors } from '../../../theme';

function defaultData(templateMode) {
  return {
    [TEMPLATE_OPTIONS.TEXTFSM]: [],
    [TEMPLATE_OPTIONS.JINJA2]: '',
  }[templateMode];
}

function OutputPanel() {
  const iteContext = useContext(ITEContext);
  const [showRaw, setShowRaw] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState(defaultData(iteContext.templateMode));
  const isTextFsm = iteContext.templateMode === TEMPLATE_OPTIONS.TEXTFSM;
  const editorMode = isTextFsm ? MODES.JSON : MODES.PLAIN_TEXT;
  const editorValue = isTextFsm ? JSON.stringify(data, null, 2) : data.toString();

  useEffect(() => {
    if (iteContext.output) {
      if (isTextFsm) {
        const head = R.head(iteContext.output);
        if (head) {
          const mappedColumns = Object.keys(head).map(col => ({
            property: col,
            header: col,
          }));
          setColumns(mappedColumns);
        }
      }
      setData(iteContext.output);
    } else {
      setData(defaultData(iteContext.templateMode));
    }
  }, [iteContext.output, isTextFsm, iteContext.templateMode]);

  return (
    <Box height="560px" style={{ borderTop: `1px solid ${colors['light-4']}`, maxWidth: '100vw' }}>
      <PanelHeader
        menuItems={[
          {
            label: <Box pad={menuPad}>Download</Box>,
            icon: (
              <Box pad={menuPad}>
                <Download size="medium" />
              </Box>
            ),
            onClick: () => {
              const fileExt = editorMode === MODES.JSON ? '.json' : '';
              const mimeType = editorMode === MODES.JSON ? 'application/json' : 'text/plain';
              download(editorValue, `template_output${fileExt}`, mimeType);
            },
          },
        ]}>
        <PanelTitle title="Output">
          {isTextFsm && (
            <Box margin={{ horizontal: 'medium' }}>
              <CheckBox
                checked={showRaw}
                label="Show raw"
                onChange={ev => setShowRaw(ev.target.checked)}
              />
            </Box>
          )}
          <Box margin={{ horizontal: 'medium' }}>
            <Button plain icon={<Copy size="medium" />} onClick={() => copy(editorValue)} />
          </Box>
        </PanelTitle>
      </PanelHeader>
      <Box style={{ overflow: 'scroll' }} fill>
        {!showRaw && isTextFsm && Array.isArray(data) ? (
          <Box>{data && data.length > 0 && <DataTable columns={columns} data={data} />}</Box>
        ) : (
          <AceEditor
            mode={editorMode}
            theme="github"
            name="output-editor"
            editorProps={{ $blockScrolling: true }}
            value={editorValue}
            readOnly
            height="100%"
            width="100%"
            style={{ flex: 1 }}
          />
        )}
      </Box>
    </Box>
  );
}

export default OutputPanel;
