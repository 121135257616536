import React, { useContext, useState } from 'react';
import { Box, Button, CheckBox, TextInput, ThemeContext } from 'grommet';
import { Add, Play, Save, Trash } from 'grommet-icons';
import { colors } from '../../../theme';
import BaseHeader, { HeaderToolbarDivider } from '../../shared/BaseHeader';
import ITEContext from '../ITEContext';

const { REACT_APP_FEATURE_WORKSPACES } = process.env;
const FEATURE_WORKSPACES = JSON.parse(REACT_APP_FEATURE_WORKSPACES);

function Header({ triggerCompile }) {
  const [autoSave, setAutoSave] = useState(false);
  const [autoCompile, setAutoCompile] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('Workspace Name');
  const iteContext = useContext(ITEContext);

  return (
    <BaseHeader>
      <Box direction="row" flex="grow" align="center" justify="end" style={{ height: '100%' }}>
        <Box direction="row" align="center">
          {FEATURE_WORKSPACES && (
            <>
              <Button plain margin="medium" icon={<Add size="medium" />} />
              <Box margin={{ horizontal: 'medium' }}>
                <TextInput
                  value={workspaceName}
                  placeholder="Workspace Name"
                  style={{ width: 240 }}
                  onChange={ev => setWorkspaceName(ev.target.value)}
                />
              </Box>

              <Button plain margin="medium" icon={<Save size="medium" />} />
              <Box margin={{ horizontal: 'medium' }}>
                <CheckBox
                  checked={autoSave}
                  label="auto-save"
                  onChange={ev => setAutoSave(ev.target.checked)}
                />
              </Box>
              <Button plain margin="medium" icon={<Trash size="medium" />} />

              <Box margin={{ horizontal: 'medium' }}>
                <HeaderToolbarDivider />
              </Box>
            </>
          )}

          <ThemeContext.Extend
            value={{
              global: {
                edgeSize: {
                  small: '6px',
                },
              },
            }}>
            <Button
              margin="medium"
              icon={<Play size="medium" />}
              onClick={triggerCompile}
              style={{
                backgroundColor: colors.accent,
                borderRadius: '50%',
              }}
            />
          </ThemeContext.Extend>
          <Box margin={{ horizontal: 'medium' }}>
            <CheckBox
              checked={autoCompile}
              label="auto-compile"
              onChange={ev => {
                const { checked } = ev.target;
                setAutoCompile(checked);
                iteContext.setAutoCompile(checked);
              }}
            />
          </Box>
        </Box>

        {JSON.parse(process.env.REACT_APP_FEATURE_PUBLISH) && (
          <Button primary label="Publish" margin={{ horizontal: 'medium' }} onClick={() => {}} />
        )}
      </Box>
    </BaseHeader>
  );
}

export default Header;
