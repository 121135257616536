import React from 'react';
import AceEditor from 'react-ace';
import 'brace/theme/github';
import 'brace/mode/json';
import 'brace/mode/plain_text';
import 'brace/mode/yaml';
import { MODES } from './modes';

function Editor({
  name,
  mode = MODES.PLAIN_TEXT,
  value,
  onChange,
  readOnly,
  height = 'auto',
  width = 'auto',
  style,
}) {
  return (
    <AceEditor
      mode={mode}
      theme="github"
      name={name}
      editorProps={{ $blockScrolling: true }}
      readOnly={readOnly}
      value={value}
      onChange={
        onChange ||
        (e => {
          console.info(e);
        })
      }
      height={height}
      width={width}
      style={{ ...style, flex: 1 }}
    />
  );
}

export default Editor;
