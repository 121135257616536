import React from 'react';
import { Box, Text } from 'grommet';

function PanelTitle({ title, children }) {
  return (
    <Box direction="row" align="center">
      <Text margin={{ horizontal: 'medium' }} style={{ whiteSpace: 'nowrap' }}>
        {title}
      </Text>
      {children}
    </Box>
  );
}

export default PanelTitle;
