import React, { useContext } from 'react';
import { Box, CheckBox, Heading, ThemeContext } from 'grommet';
import { getRGBA } from 'grommet/utils';
import helpers from '../../../common/helpers';
import { baseSpacing, colors } from '../../../theme';
import LibraryContext from '../LibraryContext';

const SidebarCheckBox = props => (
  <ThemeContext.Extend
    value={{
      checkBox: {
        gap: `${baseSpacing}px`,
      },
    }}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <CheckBox {...props} />
  </ThemeContext.Extend>
);

function Sidebar() {
  const libraryContext = useContext(LibraryContext);

  function handleFilterChangeCheckbox(checked, prop, val) {
    if (checked) {
      libraryContext.addToFilters(prop, val);
    } else {
      libraryContext.removeFromFilters(prop, val);
    }
  }

  return (
    <Box as="aside" elevation="medium" style={{ zIndex: '2' }} fill>
      <Box
        background="brand700"
        direction="row"
        align="center"
        pad={{ horizontal: 'large' }}
        style={{
          borderBottom: `1px solid ${getRGBA(colors.white, 0.24)}`,
          height: '40px',
          minHeight: '40px',
        }}>
        <Heading level="4" weight="500">
          Filters
        </Heading>
      </Box>
      <Box
        as="aside"
        background="brand700"
        pad={{ vertical: 'large', horizontal: 'large' }}
        overflow={{ vertical: 'scroll' }}
        flex="grow"
        style={{
          height: 'calc(100vh - 80px)',
        }}>
        <Heading level="5" weight="500" margin={{ vertical: 'medium' }}>
          Type
        </Heading>
        <SidebarCheckBox
          checked={libraryContext.filters.type.includes('jinja2')}
          label="Jinja2"
          onChange={ev => {
            handleFilterChangeCheckbox(ev.target.checked, 'type', 'jinja2');
          }}
        />
        <SidebarCheckBox
          checked={libraryContext.filters.type.includes('textfsm')}
          label="TextFSM"
          onChange={ev => {
            handleFilterChangeCheckbox(ev.target.checked, 'type', 'textfsm');
          }}
        />

        <Heading level="5" weight="500" margin={{ vertical: 'medium' }}>
          Vendor
        </Heading>
        {libraryContext.availableVendors.sort().map(vendor => (
          <SidebarCheckBox
            key={vendor}
            checked={libraryContext.filters.vendor.includes(vendor)}
            label={helpers.normalizeCapitalization(vendor)}
            onChange={ev => {
              handleFilterChangeCheckbox(ev.target.checked, 'vendor', vendor);
            }}
          />
        ))}

        <Heading level="5" weight="500" margin={{ vertical: 'medium' }}>
          OS
        </Heading>
        {libraryContext.availableOSs.sort().map(os => (
          <SidebarCheckBox
            key={os}
            checked={libraryContext.filters.os.includes(os)}
            label={helpers.normalizeCapitalization(os)}
            onChange={ev => {
              handleFilterChangeCheckbox(ev.target.checked, 'os', os);
            }}
          />
        ))}
      </Box>
    </Box>
  );
}

export default Sidebar;
