import React, { useCallback, useState } from 'react';
import { Box, Button, Text } from 'grommet';
import { Bundle, Cli, Code } from 'grommet-icons';
import { getRGBA } from 'grommet/utils';
import ROUTES from '../../Router/routes';
import { colors } from '../../theme';

import { ReactComponent as ItentialIconNegative } from '../../assets/itential-icon-negative.svg';
import { ReactComponent as ItentialNameNegative } from '../../assets/itential-name-negative.svg';

export const defaultSidenavContext = {
  isOpen: false,
};
export const SidenavContext = React.createContext(defaultSidenavContext);
export function useSidenav() {
  const toggleIsOpen = useCallback(
    () => setSidenavStore(ss => ({ ...ss, isOpen: !ss.isOpen })),
    [],
  );
  const [sidenavStore, setSidenavStore] = useState({
    ...defaultSidenavContext,
    toggleIsOpen,
  });

  return [sidenavStore, setSidenavStore];
}

const SidenavItem = ({ children }) => (
  <Box
    direction="row"
    align="center"
    gap="medium"
    pad={{ horizontal: 'large', vertical: 'medium' }}>
    {children}
  </Box>
);

function Sidenav({ location, navigate }) {
  const isLibraryActive = location.pathname === ROUTES.library;
  const isIteActive = location.pathname === ROUTES.ite;

  return (
    <Box
      background="brand900"
      style={{ boxShadow: 'inset -12px 0px 12px -6px rgba(0,0,0,0.56)' }}
      fill>
      <Box width="240px">
        <Button as="a" href={ROUTES.developerHub}>
          <Box
            direction="row"
            align="center"
            gap="medium"
            pad={{ horizontal: '12px', vertical: 'large' }}>
            <ItentialIconNegative
              style={{
                height: '32px',
                minHeight: '32px',
                width: '32px',
                minWidth: '32px',
              }}
            />
            <ItentialNameNegative
              style={{
                height: '20px',
                minHeight: '20px',
                width: '91px',
                minWidth: '91px',
              }}
            />
          </Box>
        </Button>
        <div
          style={{
            backgroundColor: getRGBA(colors.white, 0.12),
            width: '100%',
            height: '1px',
          }}
        />
        <Box pad={{ vertical: 'medium' }}>
          <Button onClick={() => navigate(ROUTES.library)} as="a">
            <SidenavItem>
              <Bundle size="large" color={isLibraryActive ? 'accent' : 'white'} />
              <Text
                margin={{ left: 'medium' }}
                weight={500}
                style={{ color: isLibraryActive ? colors.accent : colors.white }}>
                Library
              </Text>
            </SidenavItem>
          </Button>
          <Button onClick={() => navigate(ROUTES.ite)} as="a">
            <SidenavItem>
              <Cli size="large" color={isIteActive ? 'accent' : 'white'} />
              <Text
                margin={{ left: 'medium' }}
                weight={500}
                style={{ color: isIteActive ? colors.accent : colors.white }}>
                ITE
              </Text>
            </SidenavItem>
          </Button>
          <Button
            onClick={ev => {
              ev.preventDefaut();
              window.location.href = ROUTES.docs;
            }}
            as="a"
            href={ROUTES.docs}>
            <SidenavItem>
              <Code size="large" />
              <Text margin={{ left: 'medium' }} weight={500} truncate={true}>
                API Docs
              </Text>
            </SidenavItem>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Sidenav;
