import React from 'react';
import * as R from 'ramda';
import uuidv4 from 'uuid/v4';

const MAX_LOG_LENGTH = 100;

export const FAIL_MESSAGE = 'Failed to compile.';
export const SUCCESS_MESSAGE = 'Compiled successfully!';
export const SUCCESS_DETAILS = 'You can now view the output of your compiled data and template.';
export const defaultCompilerLogContext = {
  events: [],
  pushEvent: (event, events) => {
    const newEvent = {
      ...event,
      ts: Date.now(),
      uuid: uuidv4(),
    };
    const logLength = events.length;
    const exceedsMaxLogLength = logLength > MAX_LOG_LENGTH;

    if (exceedsMaxLogLength) {
      const logLengthDiff = logLength - MAX_LOG_LENGTH;
      return R.pipe(
        evs => R.drop(logLengthDiff + 1, evs),
        evs => R.append(newEvent, evs),
      )(events);
    }
    return R.append(newEvent, events);
  },
  resetEvents: () => [],
};

export default React.createContext(defaultCompilerLogContext);
