import React, { useContext } from 'react';
import { Box, Button } from 'grommet';
import { Clear } from 'grommet-icons';
import { colors } from '../../../theme';
import PanelHeader from '../../shared/PanelHeader';
import PanelTitle from '../../shared/PanelTitle';
import CompilerLogContext from '../CompilerLogContext';
import CompilerLog from './CompilerLog';

function CompilerLogPanel() {
  const compilerLogContext = useContext(CompilerLogContext);

  return (
    <Box width="320px" style={{ borderLeft: `1px solid ${colors['light-4']}`, flex: 1 }}>
      <PanelHeader>
        <PanelTitle title="Compiler Log">
          <Box margin={{ horizontal: 'medium' }}>
            <Button
              plain
              icon={<Clear size="medium" />}
              onClick={() => compilerLogContext.resetEvents()}
            />
          </Box>
        </PanelTitle>
      </PanelHeader>
      <CompilerLog />
    </Box>
  );
}

export default CompilerLogPanel;
