import React from 'react';

export const defaultLibraryContext = {
  templates: [],
  availableVendors: [],
  availableOSs: [],
  filters: {
    type: [],
    vendor: [],
    os: [],
  },
};
export default React.createContext(defaultLibraryContext);
