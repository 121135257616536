import React, { useContext } from 'react';
import { Box, Button, Menu } from 'grommet';
import { Logout, Menu as MenuIcon, Previous, User, UserSettings } from 'grommet-icons';
import { getRGBA } from 'grommet/utils';
import { menuPad } from '../../theme/constants';
import { colors } from '../../theme';
import { SidenavContext } from './Sidenav';
import ToolbarDivider from './ToolbarDivider';

export const HeaderToolbarDivider = () => (
  <ToolbarDivider color={getRGBA(colors.white, 0.24)} direction="vertical" />
);

function BaseHeader({ children }) {
  const sidenavContext = useContext(SidenavContext);

  return (
    <Box
      as="header"
      background="brand900"
      direction="row"
      align="center"
      justify="between"
      elevation="medium"
      style={{ zIndex: '10' }}
      fill>
      <Box direction="row" align="center" style={{ height: '100%' }}>
        <Button
          plain
          margin="medium"
          icon={!sidenavContext.isOpen ? <MenuIcon size="large" /> : <Previous size="large" />}
          onClick={sidenavContext.toggleIsOpen}
        />
        <HeaderToolbarDivider />
      </Box>
      {children}
      <Box direction="row" align="center" style={{ height: '100%' }}>
        {JSON.parse(process.env.REACT_APP_FEATURE_USER_ACCOUNT) && (
          <>
            <HeaderToolbarDivider />
            <Box margin={{ horizontal: 'medium' }}>
              <Menu
                icon={<User size="medium" />}
                dropProps={{ align: { top: 'bottom', right: 'right' } }}
                items={[
                  {
                    label: <Box pad={menuPad}>Account</Box>,
                    icon: (
                      <Box pad={menuPad}>
                        <UserSettings size="medium" />
                      </Box>
                    ),
                    onClick: () => {},
                  },
                  {
                    label: <Box pad={menuPad}>Logout</Box>,
                    icon: (
                      <Box pad={menuPad}>
                        <Logout size="medium" />
                      </Box>
                    ),
                    onClick: () => {},
                  },
                ]}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default BaseHeader;
