import React from 'react';

export const defaultLayoutState = {
  isDetailsOpen: false,
  isWorkspacesOpen: false,
  isCompilerLogOpen: false,
  isOutputOpen: true,
};

export default React.createContext(defaultLayoutState);
