import styled from 'styled-components';

const HeaderArea = styled.div`
  grid-area: header;
  display: flex;
`;
const LeftSidebarArea = styled.div`
  grid-area: left-sidebar;
  display: flex;
  height: calc(100vh - 40px);
`;
const ContentArea = styled.div`
  grid-area: content;
  display: flex;
  height: calc(100vh - 40px);
`;
const LibraryLayout = styled.div`
  display: grid;
  grid-template-columns: 240px auto;
  grid-template-rows: 40px auto;
  grid-template-areas:
    'header header'
    'left-sidebar content';
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`;

export default LibraryLayout;
export { HeaderArea, LeftSidebarArea, ContentArea };
