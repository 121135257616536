export const INPUT_OPTIONS = {
  JSON: 'JSON',
  PLAIN_TEXT: 'Plain Text',
  YAML: 'YAML',
};
export const TEMPLATE_OPTIONS = {
  JINJA2: 'Jinja2',
  TEXTFSM: 'TextFSM',
};
export const TEMPLATE_TYPE_LOOKUP = {
  [TEMPLATE_OPTIONS.JINJA2]: TEMPLATE_OPTIONS.JINJA2.toLowerCase(),
  [TEMPLATE_OPTIONS.TEXTFSM]: TEMPLATE_OPTIONS.TEXTFSM.toLowerCase(),
};

export const MODES = {
  JSON: 'json',
  PLAIN_TEXT: 'plain_text',
  YAML: 'yaml',
};
export const MODE_LOOKUP = {
  [TEMPLATE_OPTIONS.JINJA2]: MODES.PLAIN_TEXT,
  [TEMPLATE_OPTIONS.TEXTFSM]: MODES.PLAIN_TEXT,
  [INPUT_OPTIONS.JSON]: MODES.JSON,
  [INPUT_OPTIONS.PLAIN_TEXT]: MODES.PLAIN_TEXT,
  [INPUT_OPTIONS.YAML]: MODES.YAML,
};

export default {
  toTemplateType: optionType => TEMPLATE_TYPE_LOOKUP[optionType],
  toMode: optionType => MODE_LOOKUP[optionType],
};
