import React, { useContext } from 'react';
import { Terminal } from 'grommet-icons';
import ToolbarButton from '../../../shared/ToolbarButton';
import LayoutContext from '../../layout/LayoutContext';

function RightToolbar({ toggleLog }) {
  const layoutContext = useContext(LayoutContext);

  return (
    <ToolbarButton
      label="Compiler Log"
      icon={<Terminal />}
      rotate="right"
      active={layoutContext.isCompilerLogOpen}
      onClick={toggleLog}
    />
  );
}

export default RightToolbar;
