import React, { useEffect, useState } from 'react';
import { Box, Button, Text, TextInput } from 'grommet';
import debounce from 'lodash.debounce';
import stsService from '../../../common/stsService';
import BaseHeader from '../../shared/BaseHeader';
import { colors } from '../../../theme';
import styled from 'styled-components';
import { Next } from 'grommet-icons/es6';
import { navigate } from '@reach/router';
import ROUTES from '../../../Router/routes';
import helpers from '../../../common/helpers';

const debounceSearch = debounce(searchFn => {
  searchFn();
}, 200);
const List = ({ children }) => (
  <Box
    background="white"
    overflow={{ vertical: 'scroll' }}
    fill
    style={{
      position: 'absolute',
      top: '28px',
      left: '2px',
      boxShadow: '0px 4px 8px rgba(0,0,0,0.20)',
      height: '320px',
      width: 'calc(100% - 4px)',
    }}>
    {children}
  </Box>
);
const ListItem = styled.div`
  position: relative;
  border-bottom: 1px solid ${colors['light-2']};

  .launch-button {
    display: none;
  }

  &::before {
    background-color: ${colors.brand100};
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 0;
    transition: opacity 200ms ease-out;
  }
  &:last-child {
    border-bottom: 0 !important;
  }
  &:hover {
    cursor: pointer;
    .launch-button {
      display: block;
    }
  }
  &:hover::before {
    opacity: 1;
  }
`;
const ListItemInner = ({ children }) => (
  <Box
    direction="row"
    align="center"
    justify="between"
    margin="medium"
    style={{ position: 'relative', zIndex: '1' }}>
    {children}
  </Box>
);

function goToTemplate(template) {
  return navigate(`${ROUTES.library}/${template.id}`);
}

function Header() {
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    debounceSearch(async () => {
      const { data } = await stsService.search(searchValue);
      setSearchResults(data);
    });
  }, [searchValue]);

  function resetSearch() {
    setSearchValue('');
  }

  return (
    <BaseHeader>
      <Box direction="row" flex="grow" align="center" justify="center" style={{ height: '100%' }}>
        <Box margin={{ horizontal: 'medium' }} style={{ position: 'relative' }}>
          <TextInput
            value={searchValue}
            placeholder="Search"
            style={{ width: 400 }}
            onChange={ev => setSearchValue(ev.target.value)}
          />
          {searchValue.length > 0 && (
            <List>
              {searchResults.length > 0 &&
                searchResults.map(template => (
                  <ListItem
                    key={template.id}
                    onClick={() => {
                      goToTemplate(template);
                      resetSearch();
                    }}>
                    <ListItemInner>
                      <Box direction="row">
                        <Text size="small" margin={{ right: 'large' }}>
                          {template.command}
                        </Text>
                        <Text size="small" margin={{ right: 'medium' }} weight={500}>
                          OS:{' '}
                        </Text>
                        <Text size="small">{helpers.normalizeCapitalization(template.os)}</Text>
                      </Box>
                      <Button
                        plain
                        icon={<Next size="small" />}
                        onClick={() => {
                          goToTemplate(template);
                          resetSearch();
                        }}
                      />
                    </ListItemInner>
                  </ListItem>
                ))}
              {searchResults.length === 0 && (
                <ListItem>
                  <ListItemInner>
                    <Text size="small" style={{ fontStyle: 'italic' }}>
                      No results found.
                    </Text>
                  </ListItemInner>
                </ListItem>
              )}
            </List>
          )}
        </Box>
      </Box>
    </BaseHeader>
  );
}

export default Header;
