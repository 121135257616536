import colors from './colors';

const baseSpacing = 16;
const scale = 4;
const baseFontSize = baseSpacing; // 16
const fontScale = baseSpacing / scale; // 4

const fontSizing = factor => ({
  size: `${baseFontSize + factor * fontScale}px`,
  height: `${baseSpacing + factor * fontScale}px`,
  maxWidth: `${baseSpacing * (baseFontSize + factor * fontScale)}px`,
});

const theme = {
  global: {
    colors,
    control: {
      border: {
        color: 'accent',
        radius: '2px',
        width: '2px',
      },
    },
    focus: {
      border: {
        color: 'focus',
        width: undefined,
      },
    },
    font: {
      family: 'Roboto',
      size: '14px',
      height: '20px',
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: `${baseSpacing / 16}px`, // 1
      xsmall: `${baseSpacing / 8}px`, // 2
      small: `${baseSpacing / 4}px`, // 4
      medium: `${baseSpacing / 2}px`, // 8
      large: `${baseSpacing}px`, // 16
      xlarge: `${baseSpacing * 2}px`, // 32
    },
    input: {
      padding: '6px',
      weight: 500,
    },
  },
  anchor: {
    textDecoration: 'none',
    fontWeight: 500,
    color: {
      dark: 'white',
      light: 'brand',
    },
    hover: {
      textDecoration: 'underline',
    },
  },
  button: {
    border: {
      width: '2px',
      radius: '2px',
    },
    padding: {
      vertical: '4px',
      horizontal: '12px',
    },
  },
  checkBox: {
    border: {
      color: {
        dark: 'rgba(255, 255, 255, 0.5)',
        light: 'rgba(0, 0, 0, 0.15)',
      },
      width: '2px',
    },
    check: {
      radius: '2px',
      thickness: '2px',
    },
    gap: `${baseSpacing / 2}px`,
    size: `${baseSpacing}px`,
    toggle: {
      radius: `${baseSpacing}px`,
      size: `${baseSpacing * 2}px`,
    },
  },
  collapsible: {
    baseline: 560,
  },
  dataTable: {
    primary: {
      weight: 'normal',
    },
  },
  heading: {
    weight: 500,
  },
  icon: {
    size: {
      small: '12px',
      medium: '16px',
      large: '24px',
      xlarge: '32px',
    },
  },
  select: {
    icons: {
      color: { dark: 'white' },
      margin: { horizontal: 'xsmall' },
    },
    options: {
      container: {
        align: 'start',
        pad: 'none',
      },
      text: {
        margin: 'medium',
        size: '14px',
      },
    },
  },
  text: {
    xsmall: { ...fontSizing(-1) },
    small: { ...fontSizing(-0.5) },
    medium: { ...fontSizing(0) },
    large: { ...fontSizing(1) },
    xlarge: { ...fontSizing(2) },
    xxlarge: { ...fontSizing(4) },
  },
};

export default theme;
export { baseSpacing, colors };
