import React from 'react';
import { Router, Redirect } from '@reach/router';
import ITE from '../components/ITE';
import Library from '../components/Library';
import NotFound from './NotFound';
import ROUTES from './routes';

function AppRouter() {
  return (
    <Router>
      <ITE path={ROUTES.ite} />
      <Library path={ROUTES.library} />
      <Library path={ROUTES.template} />
      <NotFound path={ROUTES.notFound} />
      <Redirect from="*" to={ROUTES.notFound} />
    </Router>
  );
}

export default AppRouter;
