import React, { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';
import { Box, Collapsible } from 'grommet';
import stsService from '../../common/stsService';
import Sidenav, { SidenavContext, useSidenav } from '../shared/Sidenav';
import LibraryLayout, { HeaderArea, LeftSidebarArea, ContentArea } from './layout';
import LibraryContext, { defaultLibraryContext } from './LibraryContext';
import Header from './Header';
import Sidebar from './Sidebar';
import TemplateDetails from './TemplateDetails';
import TemplateList from './TemplateList';

const propEquality = prop => R.comparator((a, b) => a[prop] === b[prop]);
const reduceToPropValues = prop => templates =>
  R.pipe(
    R.uniqWith(propEquality(prop)),
    R.map(obj => obj[prop]),
  )(templates);
const shouldFiltersApply = filters =>
  filters.type.length > 0 || filters.vendor.length > 0 || filters.os.length > 0;

function Library({ templateId, location, navigate }) {
  const [isInitialized, setIsInitialized] = useState(false);
  /**
   * Sidenav
   */
  const [sidenavStore] = useSidenav();
  /**
   * Library
   */
  const [libraryStore, setLibraryStore] = useState({
    ...defaultLibraryContext,
    addToFilters: useCallback(
      (prop, value) =>
        setLibraryStore(ic => ({
          ...ic,
          filters: {
            ...ic.filters,
            [prop]: R.append(value, ic.filters[prop]),
          },
        })),
      [],
    ),
    removeFromFilters: useCallback(
      (prop, value) =>
        setLibraryStore(ic => ({
          ...ic,
          filters: {
            ...ic.filters,
            [prop]: R.without([value], ic.filters[prop]),
          },
        })),
      [],
    ),
  });
  const fetchTemplates = useCallback(params => {
    stsService
      .query(params)
      .then(({ data }) => setLibraryStore(ls => ({ ...ls, templates: data })))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    const filters = shouldFiltersApply(libraryStore.filters) ? libraryStore.filters : null;
    fetchTemplates(filters);
  }, [fetchTemplates, libraryStore.filters]);
  useEffect(() => {
    if (libraryStore.templates.length > 0 && !isInitialized) {
      const availableVendors = reduceToPropValues('vendor')(libraryStore.templates);
      setLibraryStore(ls => ({ ...ls, availableVendors }));
      const availableOSs = reduceToPropValues('os')(libraryStore.templates);
      setLibraryStore(ls => ({ ...ls, availableOSs }));
      setIsInitialized(true);
    }
  }, [libraryStore.templates, isInitialized]);

  return (
    <LibraryContext.Provider value={libraryStore}>
      <SidenavContext.Provider value={sidenavStore}>
        <Box direction="row">
          <Collapsible direction="horizontal" open={sidenavStore.isOpen}>
            <Sidenav location={location} navigate={navigate} />
          </Collapsible>

          <LibraryLayout>
            <HeaderArea>
              <Header />
            </HeaderArea>

            <LeftSidebarArea>
              <Box fill>
                <Sidebar />
              </Box>
            </LeftSidebarArea>

            <ContentArea>
              <Box fill>
                {!templateId ? <TemplateList /> : <TemplateDetails templateId={templateId} />}
              </Box>
            </ContentArea>
          </LibraryLayout>
        </Box>
      </SidenavContext.Provider>
    </LibraryContext.Provider>
  );
}

export default Library;
