import React, { useContext, useEffect, useState } from 'react';
import { Box, Heading, Select, Text } from 'grommet';
import helpers from '../../../common/helpers';
import Editor from '../Editor';
import modes, { TEMPLATE_OPTIONS } from '../Editor/modes';
import ITEContext from '../ITEContext';

function TemplateEditor() {
  const iteContext = useContext(ITEContext);
  const [modeOption, setModeOption] = useState(TEMPLATE_OPTIONS.TEXTFSM);
  const [template, setTemplate] = useState(iteContext.template);
  useEffect(() => {
    setTemplate(iteContext.template);
  }, [iteContext.template]);

  return (
    <>
      <Box
        as="header"
        background="brand"
        direction="row"
        align="center"
        justify="between"
        height="40px"
        pad={{ vertical: 'small', horizontal: 'medium' }}>
        <Heading level="4" weight="500">
          Template
        </Heading>
        <Box direction="row" align="center" gap="small">
          <Text size="small">Mode:</Text>
          <Select
            options={Object.keys(TEMPLATE_OPTIONS).map(k => TEMPLATE_OPTIONS[k])}
            value={modeOption}
            onChange={({ option }) => {
              setModeOption(option);
              iteContext.setTemplateMode(option);
            }}
          />
        </Box>
      </Box>
      <Editor
        name="template-editor"
        mode={modes.toMode(modeOption)}
        value={template}
        onChange={value => {
          setTemplate(value);
          helpers.debounceUpdate(iteContext.setTemplate, value);
        }}
      />
    </>
  );
}

export default TemplateEditor;
