import styled from 'styled-components';
import { colors } from '../../../theme';

const { REACT_APP_FEATURE_WORKSPACES } = process.env;
const FEATURE_WORKSPACES = JSON.parse(REACT_APP_FEATURE_WORKSPACES);

const HeaderArea = styled.div`
  grid-area: header;
  display: flex;
`;
const LeftToolbarArea = styled.div`
  grid-area: left-toolbar;
  background-color: ${colors['light-3']};
  border-right: 1px solid ${colors['light-4']};
`;
const RightToolbarArea = styled.div`
  grid-area: right-toolbar;
  background-color: ${colors['light-3']};
  border-left: 1px solid ${colors['light-4']};
`;
const FooterArea = styled.div`
  grid-area: footer;
  background-color: ${colors['light-3']};
  border-top: 1px solid ${colors['light-4']};
`;
const ContentArea = styled.div`
  grid-area: content;
  display: flex;
  height: calc(100vh - 65px);
`;
const gridTemplateAreas = FEATURE_WORKSPACES
  ? `
  'header header header'
  'left-toolbar content right-toolbar'
  'footer footer footer';
`
  : `
  'header header'
  'content right-toolbar'
  'footer footer';
`;
const ITELayout = styled.div`
  display: grid;
  grid-template-columns: ${FEATURE_WORKSPACES ? '25px auto 25px' : 'auto 25px'};
  grid-template-rows: 40px auto 25px;
  grid-template-areas: ${gridTemplateAreas};
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`;

export default ITELayout;
export { HeaderArea, LeftToolbarArea, RightToolbarArea, FooterArea, ContentArea };
