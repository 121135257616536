import React from 'react';
import { Box, Menu } from 'grommet';
import { More } from 'grommet-icons';

function PanelHeader({ menuItems, children }) {
  return (
    <Box
      background="light-3"
      direction="row"
      align="center"
      justify="between"
      height="24px"
      pad={{ horizontal: 'xsmall' }}
      style={{
        minHeight: '24px',
      }}>
      {children}
      {menuItems && (
        <Box margin={{ horizontal: 'medium' }}>
          <Menu
            icon={<More size="medium" />}
            dropProps={{ align: { top: 'bottom', right: 'right' } }}
            items={menuItems}
          />
        </Box>
      )}
    </Box>
  );
}

export default PanelHeader;
