import React, { useContext } from 'react';
import { Box, Button, Heading, InfiniteScroll, Text } from 'grommet';
import { Next } from 'grommet-icons';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import helpers from '../../../common/helpers';
import theme, { colors } from '../../../theme';
import ROUTES from '../../../Router/routes';
import LaunchButton from '../LaunchButton';
import LibraryContext from '../LibraryContext';

const List = ({ children }) => (
  <Box pad={{ vertical: 'medium' }} overflow={{ vertical: 'scroll' }} fill>
    {children}
  </Box>
);
const ListItem = styled.div`
  position: relative;
  border-bottom: 1px solid ${colors['light-2']};

  .launch-button {
    display: none;
  }

  &::before {
    background-color: ${colors.brand100};
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 0;
    transition: opacity 200ms ease-out;
  }
  &:last-child {
    border-bottom: 0 !important;
  }
  &:hover {
    cursor: pointer;
    .launch-button {
      display: block;
    }
  }
  &:hover::before {
    opacity: 1;
  }
`;
const ListItemInner = ({ children }) => (
  <Box
    direction="row"
    align="center"
    justify="between"
    margin={{ horizontal: 'xlarge' }}
    style={{ position: 'relative', zIndex: '1' }}>
    {children}
  </Box>
);
const ListItemInfo = ({ children }) => (
  <Box margin={{ vertical: 'medium' }} gap="small">
    {children}
  </Box>
);
const ListItemTitle = ({ children }) => (
  <Box direction="row" align="center" gap="large">
    {children}
  </Box>
);
const ListItemDetails = ({ children }) => (
  <Box direction="row" align="center" gap="xlarge">
    {children}
  </Box>
);
const ListItemDetailData = ({ children }) => (
  <Box direction="row" align="center">
    {children}
  </Box>
);
const ListItemActions = ({ children }) => (
  <Box direction="row" align="center" gap="large">
    {children}
  </Box>
);
const ListLabel = styled(Text)`
  background-color: ${colors.accent100};
  border-radius: 10px;
  color: ${colors.white};
  font-weight: 500;
  padding: ${theme.global.edgeSize.small} ${theme.global.edgeSize.medium};
`;
function goToTemplate(template) {
  return navigate(`${ROUTES.library}/${template.id}`);
}

function TemplateList() {
  const libraryContext = useContext(LibraryContext);
  const showJinja2Templates = libraryContext.filters.type.includes('jinja2');
  const sortedTemplates = libraryContext.templates.sort((a, b) => {
    if (a.command.toLocaleLowerCase() < b.command.toLocaleLowerCase()) return -1;
    if (a.command.toLocaleLowerCase() > b.command.toLocaleLowerCase()) return 1;
    return 0;
  });

  return (
    <>
      <Box
        background="brand"
        direction="row"
        align="center"
        pad={{ horizontal: 'large' }}
        elevation="medium"
        style={{ zIndex: '1', height: '40px', minHeight: '40px' }}>
        <Heading level={4}>Templates</Heading>
      </Box>
      {showJinja2Templates && (
        <Heading level={5} margin={{ vertical: 'large', horizontal: 'xlarge' }}>
          Jinja2 templates are coming soon!
        </Heading>
      )}
      <List className="template-list">
        <InfiniteScroll items={sortedTemplates} replace={true}>
          {template => (
            <ListItem
              key={`${template.vendor}_${template.os}_${template.command}`}
              onClick={() => goToTemplate(template)}>
              <ListItemInner>
                <ListItemInfo>
                  <ListItemTitle>
                    <Heading level={5}>{template.command}</Heading>
                    <ListLabel size="xsmall">TextFSM</ListLabel>
                  </ListItemTitle>
                  <ListItemDetails>
                    <ListItemDetailData>
                      <Text size="small" margin={{ right: 'medium' }} weight={500}>
                        Vendor:{' '}
                      </Text>
                      <Text size="small">{helpers.normalizeCapitalization(template.vendor)}</Text>
                    </ListItemDetailData>
                    <ListItemDetailData>
                      <Text size="small" margin={{ right: 'medium' }} weight={500}>
                        OS:{' '}
                      </Text>
                      <Text size="small">{helpers.normalizeCapitalization(template.os)}</Text>
                    </ListItemDetailData>
                  </ListItemDetails>
                </ListItemInfo>
                <ListItemActions>
                  <LaunchButton template={template} />
                  <Button
                    plain
                    icon={<Next size="medium" />}
                    onClick={() => goToTemplate(template)}
                  />
                </ListItemActions>
              </ListItemInner>
            </ListItem>
          )}
        </InfiniteScroll>
      </List>
    </>
  );
}

export default TemplateList;
