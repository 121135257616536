import React from 'react';

function ToolbarDivider({ color, direction = 'horizontal' }) {
  return (
    <div
      style={{
        backgroundColor: color,
        margin: '6px 2px',
        height: direction === 'vertical' ? '28px' : '1px',
        width: direction === 'vertical' ? '1px' : '100%',
      }}
    />
  );
}

export default ToolbarDivider;
