import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box } from 'grommet';
import CompilerLogContext from '../CompilerLogContext';
import LogEvent from './LogEvent';

function CompilerLog() {
  const compilerLogRef = useRef(null);
  const [events, setEvents] = useState([]);
  const compilerLogContext = useContext(CompilerLogContext);

  useEffect(() => {
    setEvents(compilerLogContext.events);

    // Handles scrolling to the bottom on new events
    const element = compilerLogRef.current;
    setTimeout(() => {
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }, 100);
  }, [compilerLogContext.events]);

  return (
    <Box overflow={{ vertical: 'scroll' }} ref={compilerLogRef}>
      {events.map(event => (
        <LogEvent key={event.uuid} event={event} />
      ))}
    </Box>
  );
}

export default CompilerLog;
