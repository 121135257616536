import React, { useEffect, useState } from 'react';
import { Box, Button, Heading, Text } from 'grommet';
import { Previous } from 'grommet-icons';
import { navigate } from '@reach/router';
import { colors } from '../../../theme';
import helpers from '../../../common/helpers';
import stsService from '../../../common/stsService';
import PanelTitle from '../../shared/PanelTitle';
import PanelHeader from '../../shared/PanelHeader';
import ROUTES from '../../../Router/routes';
import { MODES } from '../../ITE/Editor/modes';
import Editor from '../../ITE/Editor';
import LaunchButton from '../LaunchButton';

function goToLibrary() {
  return navigate(ROUTES.library);
}
function goToNotFound() {
  return navigate(ROUTES.notFound);
}

function TemplateDetails({ templateId }) {
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    stsService
      .find(templateId)
      .then(({ data }) => setTemplate(data))
      .catch(error => {
        console.error(error);
        if (error.response.status === 404) {
          goToNotFound();
        }
      });
  }, [templateId]);

  return (
    <>
      <Box
        background="brand"
        direction="row"
        justify="between"
        align="center"
        pad={{ horizontal: 'large' }}
        elevation="medium"
        style={{ zIndex: '1', height: '40px', minHeight: '40px' }}>
        <Box direction="row" align="center" gap="large">
          <Button label="Back" icon={<Previous size="medium" />} onClick={() => goToLibrary()} />
          <Heading level={4}>Preview</Heading>
        </Box>
        <LaunchButton template={template} />
      </Box>
      {template && (
        <Box direction="row" fill>
          <Box flex="grow">
            <Editor
              name="template-editor"
              mode={MODES.PLAIN_TEXT}
              value={template.template || ''}
              readOnly
            />
          </Box>
          <Box style={{ borderLeft: `1px solid ${colors['light-2']}`, width: '240px' }}>
            <PanelHeader>
              <PanelTitle title="Template Details" />
            </PanelHeader>
            <Box pad={{ vertical: 'medium', horizontal: 'large' }}>
              <Box margin={{ vertical: 'small' }} gap="medium">
                <Box gap="xsmall">
                  <Text size="small" weight={500}>
                    Vendor
                  </Text>
                  <Text size="small">{helpers.normalizeCapitalization(template.vendor)}</Text>
                </Box>
                <Box gap="xsmall">
                  <Text size="small" weight={500}>
                    OS
                  </Text>
                  <Text size="small">{helpers.normalizeCapitalization(template.os)}</Text>
                </Box>
                <Box gap="xsmall">
                  <Text size="small" weight={500}>
                    Command
                  </Text>
                  <Text size="small">{template.command}</Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default TemplateDetails;
