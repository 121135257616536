import React from 'react';
import { Box, Text } from 'grommet';
import { colors } from '../../../theme';

const EVENT_MARGIN = {
  vertical: 'xsmall',
};
const EVENT_FONT_STYLE = { fontFamily: "'Fira Code', monospace" };
const EVENT_MESSAGE_FONT_STYLE = { fontSize: '12px', lineHeight: '16px', fontWeight: '600' };
const EVENT_DETAILS_FONT_STYLE = { fontSize: '11px', lineHeight: '14px' };

function LogEvent({ event }) {
  const isErrorEvent = Object.prototype.hasOwnProperty.call(event, 'error');
  const eventColor = isErrorEvent ? 'status-error' : 'status-ok';

  return (
    <Box
      pad={{
        vertical: 'large',
        horizontal: 'medium',
      }}
      style={{ borderBottom: `1px solid ${colors['light-3']}`, minHeight: 'auto' }}>
      <Text
        color={eventColor}
        level={5}
        size="medium"
        margin={EVENT_MARGIN}
        style={{ ...EVENT_FONT_STYLE, ...EVENT_MESSAGE_FONT_STYLE }}>
        {event.message}
      </Text>
      <Text
        color={eventColor}
        size="medium"
        margin={EVENT_MARGIN}
        style={{ ...EVENT_FONT_STYLE, ...EVENT_DETAILS_FONT_STYLE }}>
        {event.details}
        {isErrorEvent && ` — ${event.error}`}
      </Text>
    </Box>
  );
}

export default LogEvent;
