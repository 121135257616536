import React from 'react';
import { Box, Button, Text } from 'grommet';
import { colors } from '../../theme';

function ToolbarButton({ label, icon, rotate, active, style, onClick }) {
  const isRotated = rotate === 'left' || rotate === 'right';
  const rotation = rotate === 'left' ? -90 : 90;
  const transformStyles = isRotated
    ? {
        transform: `rotate(${rotation}deg)`,
        transformOrigin: '12px 50%',
      }
    : {};

  return (
    <Button
      onClick={onClick}
      hoverIndicator="light-1"
      style={{
        ...style,
        ...transformStyles,
        height: 24,
        ...(active && { backgroundColor: colors['light-1'] }),
      }}>
      <Box
        direction={rotate === 'left' || rotate === 'right' ? 'row' : 'row'}
        align="center"
        gap="medium"
        pad={{ horizontal: 'medium', vertical: 'xsmall' }}>
        {icon}
        <Text size="small" style={{ whiteSpace: 'nowrap' }}>
          {label}
        </Text>
      </Box>
    </Button>
  );
}

export default ToolbarButton;
