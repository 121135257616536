import React from 'react';
import { Launch } from 'grommet-icons';
import { Button } from 'grommet';
import { navigate } from '@reach/router';
import ROUTES from '../../Router/routes';

function goToIte(template) {
  return navigate(ROUTES.ite, { state: { template } });
}

function LaunchButton({ template }) {
  return (
    <Button
      className="launch-button"
      color="accent"
      label="Launch"
      icon={<Launch size="medium" />}
      gap="medium"
      onClick={ev => {
        ev.stopPropagation();
        return goToIte(template);
      }}
    />
  );
}

export default LaunchButton;
