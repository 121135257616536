import React from 'react';
import styled from 'styled-components';
import { Button, Heading, Text, ThemeContext } from 'grommet';
import { LinkNext } from 'grommet-icons';

import { ReactComponent as DesertSun } from '../assets/desert-sun.svg';
import { ReactComponent as DesertLandscape } from '../assets/desert-landscape.svg';
import ROUTES from './routes';

const NotFoundInner = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Sun = styled(DesertSun)`
  position: absolute;
  top: 40px;
  left: 80px;
  z-index: 0;
`;
const Landscape = styled(DesertLandscape)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
`;
const IconCredit = styled.div`
  position: absolute;
  bottom: 8px;
  right: 16px;
  z-index: 2;
  color: #e8af2a;
  a {
    color: #df9d27;
  }
`;
const Message = styled.div`
  background-color: rgba(255, 255, 255, 0.24);
  border-radius: 22px;
  position: relative;
  z-index: 3;
  padding: 32px;
  //display: flex;
  //flex-direction: column;
`;

function NotFound({ navigate }) {
  return (
    <NotFoundInner>
      <Message>
        <Heading
          level={2}
          color="brand700"
          size="xlarge"
          margin={{ bottom: 'xlarge' }}
          style={{ fontStyle: 'italic' }}>
          404
        </Heading>
        <Heading level={3} color="brand700" size="xlarge" margin={{ vertical: 'medium' }}>
          Looks like you are lost.
        </Heading>
        <Text color="brand700" size="xlarge" margin={{ vertical: 'medium' }}>
          The page you are looking for is not available.
        </Text>
        <ThemeContext.Extend
          value={{
            text: {
              medium: {
                size: '24px',
                height: '32px',
              },
            },
            button: {
              border: {
                width: '2px',
                radius: '22px',
              },
              padding: {
                vertical: '4px',
                horizontal: '20px',
              },
            },
          }}>
          <Button
            primary
            label="Go to Home"
            icon={<LinkNext />}
            reverse
            margin={{ top: 'xlarge' }}
            style={{ display: 'block' }}
            onClick={() => navigate(ROUTES.ite)}
          />
        </ThemeContext.Extend>
      </Message>
      <Sun />
      <Landscape />
      <IconCredit>
        Icons made by{' '}
        <a href="https://www.flaticon.com/authors/vectors-market" title="Vectors Market">
          Vectors Market
        </a>{' '}
        from{' '}
        <a href="https://www.flaticon.com/" title="Flaticon">
          www.flaticon.com
        </a>
      </IconCredit>
    </NotFoundInner>
  );
}

export default NotFound;
