import React, { useContext } from 'react';
import { CircleInformation, List } from 'grommet-icons';
import ToolbarButton from '../../../shared/ToolbarButton';
import LayoutContext from '../../layout/LayoutContext';

function LeftToolbar({ toggleDetails, toggleWorkspaces }) {
  const layoutContext = useContext(LayoutContext);

  return (
    <>
      <ToolbarButton
        label="Details"
        icon={<CircleInformation />}
        rotate="left"
        active={layoutContext.isDetailsOpen}
        style={{ position: 'relative', top: '59px' }}
        onClick={toggleDetails}
      />
      <ToolbarButton
        label="Workspaces"
        icon={<List />}
        rotate="left"
        active={layoutContext.isWorkspacesOpen}
        style={{ position: 'relative', top: '151px' }}
        onClick={toggleWorkspaces}
      />
    </>
  );
}

export default LeftToolbar;
