import React, { useContext, useState } from 'react';
import { Box, Heading, Select, Text } from 'grommet';
import helpers from '../../../common/helpers';
import Editor from '../Editor';
import modes, { INPUT_OPTIONS } from '../Editor/modes';
import ITEContext from '../ITEContext';

function DataEditor() {
  const [modeOption, setModeOption] = useState(INPUT_OPTIONS.PLAIN_TEXT);
  const [data, setData] = useState('');
  const iteContext = useContext(ITEContext);

  return (
    <>
      <Box
        as="header"
        background="brand"
        direction="row"
        align="center"
        justify="between"
        height="40px"
        pad={{ vertical: 'small', horizontal: 'medium' }}>
        <Heading level="4" weight="500">
          Data
        </Heading>
        <Box direction="row" align="center" gap="small">
          <Text size="small">Mode:</Text>
          <Select
            options={Object.keys(INPUT_OPTIONS).map(k => INPUT_OPTIONS[k])}
            value={modeOption}
            onChange={({ option }) => {
              setModeOption(option);
              iteContext.setDataMode(option);
            }}
          />
        </Box>
      </Box>
      <Editor
        name="data-editor"
        mode={modes.toMode(modeOption)}
        value={data}
        onChange={value => {
          setData(value);
          helpers.debounceUpdate(iteContext.setData, value);
        }}
      />
    </>
  );
}

export default DataEditor;
