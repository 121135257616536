const brandColor = '#0094d9';
const accentColor = '#F37622';
const darkColors = ['#333333', '#555555', '#777777', '#999999', '#999999', '#999999'];
const lightColors = ['#F8F8F8', '#F2F2F2', '#EDEDED', '#DADADA', '#DADADA', '#DADADA'];

const colors = {
  black: '#000000',
  brand: brandColor,
  brand100: '#E5F2FE',
  brand300: '#1EB4FF',
  brand700: '#00699C',
  brand900: '#002C41',
  accent: accentColor,
  'accent-1': accentColor, // for compatibility with base theme
  accent100: '#FFAC73',
  accent300: '#FF944C',
  accent700: '#C85609',
  accent900: '#8E4000',
  control: {
    dark: 'accent',
    light: 'brand',
  },
  focus: accentColor,
  placeholder: '#AAAAAA',
  selected: 'brand',
  text: {
    dark: '#f8f8f8',
    light: '#444444',
  },
  icon: {
    dark: '#f8f8f8',
    light: '#666666',
  },
  white: '#FFFFFF',
  'status-critical': '#AF1300',
  'status-error': '#AF1300',
  'status-warning': '#F9A825',
  'status-ok': '#0CAC00',
  'status-unknown': '#CCCCCC',
  'status-disabled': '#CCCCCC',
};

const colorArray = (array, prefix) =>
  array.forEach((color, index) => {
    colors[`${prefix}-${index + 1}`] = color;
  });
colorArray(darkColors, 'dark');
colorArray(lightColors, 'light');

export default colors;
