import axios from 'axios';
import * as queryString from 'query-string';

const STS_BASE_URL = 'https://template.itentialapis.io/v1';
const TEMPLATES_ROUTE = `${STS_BASE_URL}/templates`;

const authorizationHeader = {
  Authorization:
    'Bearer feeb1edef1ec7edba5eba11c011ec7ed-5eaf00d5a1ad5ba11ad1a57edb1a57ed-1eaf1e55f100dedfacade0b57ac1ebed-de1ec7ab1edecafc0ffee50da1ad1ev2',
};
const genericRequestConfig = {
  headers: {
    ...authorizationHeader,
  },
};

async function compile(template, data, { templateType }) {
  try {
    const response = await axios.post(
      `${STS_BASE_URL}/compile`,
      {
        template_type: templateType,
        template,
        data,
      },
      genericRequestConfig,
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw error.response;
    }
    throw error;
  }
}

async function query(parameters) {
  const url = !parameters
    ? TEMPLATES_ROUTE
    : `${TEMPLATES_ROUTE}?${queryString.stringify(parameters)}`;
  try {
    const response = await axios.get(url, genericRequestConfig);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw error.response;
    }
    throw error;
  }
}

async function search(value = '') {
  const url = `${TEMPLATES_ROUTE}?${queryString.stringify({ search: value })}`;
  try {
    const response = await axios.get(url, genericRequestConfig);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw error.response;
    }
    throw error;
  }
}

async function find(templateId) {
  const url = `${TEMPLATES_ROUTE}/${templateId}`;
  try {
    const response = await axios.get(url, genericRequestConfig);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw error.response;
    }
    throw error;
  }
}

export default {
  compile,
  query,
  search,
  find,
};
