import React, { useCallback, useEffect, useState } from 'react';
import { Box, Collapsible } from 'grommet';
import ROUTES from '../../Router/routes';
import stsService from '../../common/stsService';
import Sidenav, { SidenavContext, useSidenav } from '../shared/Sidenav';
import ITELayout, {
  HeaderArea,
  LeftToolbarArea,
  RightToolbarArea,
  FooterArea,
  ContentArea,
} from './layout';
import CompilerLogContext, {
  defaultCompilerLogContext,
  FAIL_MESSAGE,
  SUCCESS_MESSAGE,
  SUCCESS_DETAILS,
} from './CompilerLogContext';
import LayoutContext, { defaultLayoutState } from './layout/LayoutContext';
import ITEContext, { defaultIteContext } from './ITEContext';
import modes from './Editor/modes';
import LeftToolbar from './Toolbars/LeftToolbar';
import RightToolbar from './Toolbars/RightToolbar';
import BottomToolbar from './Toolbars/BottomToolbar';
import CompilerLogPanel from './CompilerLogPanel';
import DataEditor from './DataEditor';
import DetailsPanel from './DetailsPanel';
import Header from './Header';
import OutputPanel from './OutputPanel';
import TemplateEditor from './TemplateEditor';
import WorkspacesPanel from './WorkspacesPanel';

const { REACT_APP_FEATURE_WORKSPACES } = process.env;
const FEATURE_WORKSPACES = JSON.parse(REACT_APP_FEATURE_WORKSPACES);

function ITE({ location, navigate }) {
  /**
   * Sidenav
   */
  const [sidenavStore] = useSidenav();
  /**
   * Layout
   */
  const [layout, setLayout] = useState(defaultLayoutState);
  /**
   * ITE Store (DataEditor, TemplateEditor, Output)
   */
  const setOutput = useCallback(output => setIteStore(ic => ({ ...ic, output })), []);
  const setAutoCompile = useCallback(
    autoCompile => setIteStore(ic => ({ ...ic, autoCompile })),
    [],
  );
  const setDataMode = useCallback(dataMode => setIteStore(ic => ({ ...ic, dataMode })), []);
  const setTemplateMode = useCallback(
    templateMode => setIteStore(ic => ({ ...ic, templateMode })),
    [],
  );
  const setData = useCallback(data => setIteStore(ic => ({ ...ic, data })), []);
  const setTemplate = useCallback(template => setIteStore(ic => ({ ...ic, template })), []);
  const [iteStore, setIteStore] = useState({
    ...defaultIteContext,
    setAutoCompile,
    setDataMode,
    setTemplateMode,
    setData,
    setTemplate,
    setOutput,
  });
  /**
   * Compiler Log Store
   */
  const pushEvent = useCallback(
    event =>
      setCompilerLogStore(cls => ({
        ...cls,
        events: cls.pushEvent(event, cls.events),
      })),
    [],
  );
  const resetEvents = useCallback(
    () =>
      setCompilerLogStore(cls => ({
        ...cls,
        events: cls.resetEvents(),
      })),
    [],
  );
  const pushErrorEvent = useCallback(
    error => {
      if (error.data) {
        pushEvent({
          message: FAIL_MESSAGE,
          details: error.data.message,
          error: error.data.error,
        });
      } else {
        pushEvent({
          message: FAIL_MESSAGE,
          details: 'Unknown error',
          error: error.toString(),
        });
      }
    },
    [pushEvent],
  );
  const [compilerLogStore, setCompilerLogStore] = useState({
    ...defaultCompilerLogContext,
    resetEvents,
  });

  /**
   * Global effects based on state store changes
   */
  useEffect(() => {
    if (location.state && location.state.template) {
      setTemplate(location.state.template.template);
      navigate(ROUTES.ite, { replace: true, state: {} });
    }
  }, [location.state, navigate, setTemplate]);
  useEffect(() => {
    // auto-close/auto-open panels in the same space
    if (layout.isDetailsOpen) {
      setLayout(l => ({ ...l, isWorkspacesOpen: false }));
    }
  }, [layout.isDetailsOpen]);
  useEffect(() => {
    // auto-close/auto-open panels in the same space
    if (layout.isWorkspacesOpen) {
      setLayout(l => ({ ...l, isDetailsOpen: false }));
    }
  }, [layout.isWorkspacesOpen]);
  useEffect(() => {
    // enables auto-compiling
    if (iteStore.autoCompile) {
      stsService
        .compile(iteStore.template, iteStore.data, {
          templateType: modes.toTemplateType(iteStore.templateMode),
        })
        .then(({ data }) => {
          setOutput(data);
          pushEvent({
            message: SUCCESS_MESSAGE,
            details: SUCCESS_DETAILS,
          });
        })
        .catch(error => pushErrorEvent(error));
    }
  }, [
    iteStore.autoCompile,
    iteStore.templateMode,
    iteStore.template,
    iteStore.data,
    setOutput,
    pushEvent,
    pushErrorEvent,
  ]);
  useEffect(() => {
    // Reset output when the template mode changes
    setOutput(null);
  }, [iteStore.templateMode, setOutput]);

  return (
    <ITEContext.Provider value={iteStore}>
      <CompilerLogContext.Provider value={compilerLogStore}>
        <LayoutContext.Provider value={layout}>
          <SidenavContext.Provider value={sidenavStore}>
            <Box direction="row">
              <Collapsible direction="horizontal" open={sidenavStore.isOpen}>
                <Sidenav location={location} navigate={navigate} />
              </Collapsible>

              <ITELayout>
                <HeaderArea>
                  <Header
                    triggerCompile={() => {
                      stsService
                        .compile(iteStore.template, iteStore.data, {
                          templateType: modes.toTemplateType(iteStore.templateMode),
                        })
                        .then(({ data }) => {
                          setOutput(data);
                          setLayout({ ...layout, isOutputOpen: true });
                          pushEvent({
                            message: SUCCESS_MESSAGE,
                            details: SUCCESS_DETAILS,
                          });
                        })
                        .catch(error => pushErrorEvent(error));
                    }}
                  />
                </HeaderArea>

                {FEATURE_WORKSPACES && (
                  <LeftToolbarArea>
                    <LeftToolbar
                      toggleDetails={() =>
                        setLayout({ ...layout, isDetailsOpen: !layout.isDetailsOpen })
                      }
                      toggleWorkspaces={() =>
                        setLayout({ ...layout, isWorkspacesOpen: !layout.isWorkspacesOpen })
                      }
                    />
                  </LeftToolbarArea>
                )}

                <ContentArea>
                  <Box fill>
                    <Box direction="row" fill>
                      <Collapsible
                        direction="horizontal"
                        open={layout.isDetailsOpen || layout.isWorkspacesOpen}>
                        {layout.isDetailsOpen && <DetailsPanel />}
                        {layout.isWorkspacesOpen && <WorkspacesPanel />}
                      </Collapsible>

                      <Box style={{ flex: 1 }}>
                        <DataEditor />
                      </Box>
                      <Box style={{ flex: 1 }}>
                        <TemplateEditor />
                      </Box>

                      <Collapsible direction="horizontal" open={layout.isCompilerLogOpen}>
                        <CompilerLogPanel />
                      </Collapsible>
                    </Box>

                    <Collapsible open={layout.isOutputOpen}>
                      <OutputPanel />
                    </Collapsible>
                  </Box>
                </ContentArea>

                <RightToolbarArea>
                  <RightToolbar
                    toggleLog={() =>
                      setLayout({ ...layout, isCompilerLogOpen: !layout.isCompilerLogOpen })
                    }
                  />
                </RightToolbarArea>

                <FooterArea>
                  <BottomToolbar
                    toggleOutput={() =>
                      setLayout({ ...layout, isOutputOpen: !layout.isOutputOpen })
                    }
                  />
                </FooterArea>
              </ITELayout>
            </Box>
          </SidenavContext.Provider>
        </LayoutContext.Provider>
      </CompilerLogContext.Provider>
    </ITEContext.Provider>
  );
}

export default ITE;
