import React, { useContext } from 'react';
import { Box, Text } from 'grommet';
import { Table } from 'grommet-icons';
import ToolbarButton from '../../../shared/ToolbarButton';
import LayoutContext from '../../layout/LayoutContext';

const CURRENT_YEAR = new Date().getFullYear();

function BottomToolbar({ toggleOutput }) {
  const layoutContext = useContext(LayoutContext);

  return (
    <Box as="footer" direction="row" align="center" justify="between" fill>
      <Text size="xsmall" margin={{ horizontal: 'xlarge' }}>
        &copy; {CURRENT_YEAR} Itential
      </Text>
      <div style={{ marginRight: 25 }}>
        <ToolbarButton
          label="Output"
          icon={<Table />}
          active={layoutContext.isOutputOpen}
          onClick={toggleOutput}
        />
      </div>
    </Box>
  );
}

export default BottomToolbar;
