import React from 'react';
import { INPUT_OPTIONS, TEMPLATE_OPTIONS } from './Editor/modes';

export const defaultIteContext = {
  dataMode: INPUT_OPTIONS.PLAIN_TEXT,
  templateMode: TEMPLATE_OPTIONS.TEXTFSM,
  data: '',
  template: '',
  output: null,
  autoCompile: false,
};

export default React.createContext(defaultIteContext);
